<template>
  <div v-if="swotItems.length > 0" class="w-full mt-3 mb-8">
    <h2 class="text-2xl font-semibold">
      <div class="flex items-center mb-8 text-blue-800 dark:text-blue-400">
        {{ title }}
        <button @click="copySection(swotItems, title)" class="copy-all-icon focus:outline-none">
          <svg
            class="w-8 h-8 ml-2 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
            />
          </svg>
        </button>
      </div>
    </h2>
    <div class="overflow-hidden bg-white shadow sm:rounded-md ">
      <ul class="divide-y divide-gray-200 dark:divide-gray-500">
        <li v-for="swotItem in swotItems" :key="swotItem.id" class="mb-0">
          <Item
            :text="swotItem.text"
            :from="swotItem.from"
            :checkedItem="swotItem.checked"
            :type="collection"
            :publishedAt="swotItem.publishedAt"
            :swot="swotItem.swot"
            :id="swotItem.id"
            :clientId="clientId"
          ></Item>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { repgen } from "@/firebase";
  import { collection, orderBy, onSnapshot, query } from "firebase/firestore"

  import Item from "@/components/swot/Item.vue";

  export default {
    props: ["title", "collection", "clientId"],
    components: { Item },
    data() {
      return {
        swotItems: [],
        copied: "",
      };
    },
    mounted() {
      const q = query(collection(repgen, "swots", this.clientId, this.collection), orderBy("publishedAt", "asc"))
      onSnapshot(q, (querySnapshot) => {
        this.swotItems = [];
          querySnapshot.forEach((doc) => {
            let data = {
              text: doc.data().text,
              from: doc.data().from,
              swot: doc.data().swot,
              checked: doc.data().checked,
              publishedAt: doc.data().publishedAt,
              id: doc.id,
            };
            this.swotItems.push(data);
          });
      });
    },
    methods: {
      copySection(items, title) {
        this.copied = "";
        this.copied = `${title}\n\n`;
        for (var i = 0; i < items.length; i++) {
          this.copied += items[i].text + "\n\n";
        }
        this.copied += "\n";
        navigator.clipboard.writeText(this.copied);
        // this.$notify(
        //   {
        //     group: "top",
        //     text: `${title} have been copied`,
        //     type: "info", // info, warning
        //   },
        //   4000
        // );
      },
    },
  };
</script>

<style></style>
