<template>
  <div class="block w-full bg-white dark:bg-gray-600 hover:bg-gray-50">
    <div class="flex items-center py-2 pl-0 pr-4">
      <div class="flex items-center w-full">
        <div class="flex items-center w-full jusitfy-between">
          <div class="w-8 pl-3 text-center">
            <label class="inline-flex items-center mt-2">
              <input
                v-model="checked"
                type="checkbox"
                v-on:input="checkSwotItem(id)"
                class="w-5 h-5 text-gray-600 bg-white dark:bg-gray-500 dark:hover:bg-gray-400 dark:text-gray-300 form-checkbox"
              />
            </label>
          </div>

          <div
            v-if="!isEdit"
            class="w-5/6 px-4 break-word"
            :class="checked ? 'line-through text-gray-300' : 'text-gray-600 dark:text-gray-300'"
          >
            {{ text }}
          </div>
          <div v-else class="flex w-5/6 px-4 break-word">
            <div class="w-5/6 px-2 py-2 border border-gray-200 rounded dark:border-gray-700">
              <textarea
                class="w-full h-full px-2 text-gray-600 bg-white rounded resize-y dark:bg-gray-400 focus:outline-none"
                v-model="textArea"
                rows="4"
              >
              </textarea>
            </div>

            <button @click="updateSWOT(id, text)" class="px-3 py-1 mx-2 text-xs text-white bg-green-300 rounded">
              Save
            </button>
            <button @click="isEdit = !isEdit" class="px-3 py-1 mr-2 text-xs text-white bg-gray-300 rounded">
              Cancel
            </button>
          </div>

          <div class="w-32 text-center">
            <div>
              <p class="py-1 mx-2 text-sm text-white bg-blue-400 rounded ">
                {{ from }}
              </p>
              <span class="text-xs text-gray-400">
                ({{ formatDate(publishedAt) }})
                <!-- ({{publishedAt.seconds }}) -->
              </span>
            </div>
          </div>
          <!-- <div class="w-24 text-center">
            <div>
              <p class="py-1 text-sm font-semibold text-blue-500 truncate">
                Process Development
              </p>
            </div>
          </div> -->
        </div>
      </div>
      <div class="flex justify-between">
        <button @click="copyItem(text)" class="focus:outline-none">
          <svg
            class="w-6 h-6 mr-2 text-blue-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
            />
          </svg>
        </button>
        <button @click="isEdit = !isEdit" class="focus:outline-none">
          <svg
            class="w-6 h-6 mr-2 text-blue-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          </svg>
        </button>
        <button @click="isDelete = !isDelete" class="focus:outline-none">
          <svg
            class="w-6 h-6 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
      </div>
    </div>
    <DeleteModal
      v-if="isDelete"
      :text="text"
      :id="id"
      :client="clientId"
      :type="type"
      @cancel-delete="cancelDelete()"
    />
  </div>
</template>

<script>
  import DeleteModal from "@/components/DeleteModal.vue";
  import { repgen } from "@/firebase";
  import { doc, setDoc, updateDoc } from "firebase/firestore"

  export default {
    name: "Strength",
    components: {
      DeleteModal,
    },
    props: ["text", "from", "type", "publishedAt", "swot", "id", "allSWOT", "checkedItem", "clientId"],
    data() {
      return {
        isEdit: false,
        isDelete: false,
        strikeThrough: false,
        checked: this.checkedItem,
        textArea: this.text,
      };
    },
    methods: {
      async updateSWOT() {
        console.log(this.text);
        console.log(this.clientId, this.type, this.id);
        await setDoc(doc(repgen, "swots", this.clientId, this.type, this.id), {
            from: this.from,
            publishedAt: this.publishedAt,
            swot: this.swot,
            text: this.textArea,
        })
        this.isEdit = !this.isEdit;
      },
      checkSwotItem() {
        this.checked = !this.checked;
        updateDoc(doc(repgen, "swots", this.clientId, this.type, this.id), {
          checked: this.checked
        });
      },
      copyItem(text) {
        this.copied = "";

        this.copied += text;
        navigator.clipboard.writeText(this.copied);
        // this.$notify(
        //   {
        //     group: "top",
        //     text: `SWOT item has been copied`,
        //     type: "info",
        //   },
        //   4000
        // );
      },
      cancelDelete() {
        this.isDelete = false;
      },
      resetCheckbox() {
        setTimeout(() => {
          this.checked = false;
        }, 1000);
      },
      formatDate(timestamp) {
        let date = timestamp.toDate();
        let mm = date.getMonth() + 1;
        let dd = date.getDate();
        let yyyy = date.getFullYear();
        let hh = date.getHours().toString().padStart(2, "0");
        let minutes = date.getMinutes().toString().padStart(2, "0");
        date = mm + "/" + dd + "/" + yyyy + " - " + hh + ":" + minutes;
        return date;
      },
    },
  };
</script>

<style scoped></style>
